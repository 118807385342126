<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require(`@/assets/icons/${icon}`)"
        height="56"
        width="56"
        contain
      />

      <component
        :is="m_strTitle.strComponent"
        :class="getTextClasses(m_strTitle.eType, ['text-uppercase',`${textColor}--text`])"
        space="1"
      >
        {{ m_strTitle.strText }}
      </component>

      <base-divider
        :color="color"
        align="center"
      />

      <base-body
        v-if="strDescription || $slots.default"
        class="font-weight-thin text-center"
        space="6"
        :text-color="textColor"
      >
        {{ strDescription }}
      </base-body>
    </div>
    <!-- </v-container> -->
  </v-theme-provider>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDASpecialtyCard',

    mixins: [TextClass],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary1',
      },
      icon: String,
      strTitle: String,
      strDescription: String,
      textColor: {
        type: String,
        default: 'Grey',
      },
    },

    computed: {
      m_strTitle () {
        return {
          strText: this.strTitle,
          strComponent: 'h1',
          eType: this.eContentType.SUBTITLE_1,
        }
      },
    },
  }
</script>
